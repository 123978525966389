"use strict";

document.addEventListener( 'DOMContentLoaded', function () {

    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide heroSlider
    // *
    // *

    const heroSlider = document.getElementsByClassName('js-hero-slider');
    
    if (heroSlider !== null) {
        for (let i = 0; i < heroSlider.length; i++) {
            new Splide(heroSlider[i], {
                type            : 'fade',
                pauseOnHover    : true,
                rewind          : true,
                lazyLoad        : 'nearby'
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide imageSlider
    // *
    // *

    const imageSlider = document.getElementsByClassName('js-image-slider');
    
    if (imageSlider !== null) {
        for (let i = 0; i < imageSlider.length; i++) {
            new Splide(imageSlider[i], {
                type            : 'fade',
                pauseOnHover    : true,
                rewind          : true,
                lazyLoad        : 'nearby'
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * splide textSlider
    // *
    // *

    const textSlider = document.getElementsByClassName('js-text-slider');
    
    if (textSlider !== null) {
        for (let i = 0; i < textSlider.length; i++) {
            new Splide(textSlider[i], {
                type            : 'slide',
                rewind          : true,
                arrows          : true,
                pagination      : true,
                lazyLoad        : 'nearby',
                perPage         : 1,
                gap             : '3rem',
                breakpoints: {
                    768: {
                        perPage: 1,
                    }
                }
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollToTop
    // *
    // *
    
    const scrolltotop  = document.querySelector(".js-scrolltop");

    if (scrolltotop !== null) {
        // show and hide scrollToTop btn on scroll
        document.addEventListener('scroll', function(e) {
            if (window.scrollY > 300) {
                scrolltotop.classList.add("show");
            } else {
                scrolltotop.classList.remove("show");
            }
        });

        // onclick scroll to top
        scrolltotop.onclick = function(e){
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

        // onkeydown scroll to top or bottom
        scrolltotop.addEventListener("keydown", function (event) {
            if (event.keyCode === 13 || event.keyCode === 32) {
                event.preventDefault();
    
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }   
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    // * anchor nav scrollTo
    // *
    // *
    
    // get browser width
    let browserWidth = window.innerWidth;
    let anchorBox = document.querySelector('.js-anchor-nav');
    let anchorBoxSticky = document.querySelector('.js-anchor-sticky');

    // check if anchor modul is set to sticky
    if (anchorBoxSticky !== null) {
        window.addEventListener('scroll', function(e) {
            // get pos from anchorbox
            let pos = Math.round(anchorBox.getBoundingClientRect().y);

            if (pos <= 0) {
                anchorBox.classList.add("stick");
            } else {
                anchorBox.classList.remove("stick");
            }
        });
    }

    // check if anchor modul is set
    if (anchorBox !== null) {
        // needed for sticky anchor to calculate offsetTop
        anchorBoxHeight = anchorBox.offsetHeight;

        if (browserWidth > 1280) {
            anchorBoxHeight = anchorBoxHeight;
        } else {
            anchorBoxHeight = 0;
        }

        const anchors = document.querySelectorAll(".js-scrollTo"); 
        
        for (const anchor of anchors) {
            anchor.addEventListener("click", clickHandler);
        }

        function clickHandler(e) {
            e.preventDefault();
        
            let href = this.getAttribute("href").split("#");
            let newAnchor = "#my" + href[1]
            let offsetTop = document.querySelector(newAnchor).offsetTop - anchorBoxHeight;

            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * modal window
    // *
    // *

    let modalWindow = document.querySelector(".js-modal");
    let modalOpen = document.querySelector(".js-modal-btn-open");
    let modalClose = document.querySelector(".js-modal-btn-close");
    
    /* onclick show modal window */
    if (modalOpen !== null) {
        modalOpen.onclick = function() {
            modalWindow.style.display = "block";
            modalClose.focus();
        }
    }
    
    /* close modal window */
    if (modalClose !== null) {
        modalClose.onclick = function() {
            modalWindow.style.display = "none";
        }
    }
    
    /* clicks anywhere outside close modal window */
    window.onclick = function(event) {
        if (event.target == modalWindow) {
            modalWindow.style.display = "none";
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion open every tab
    // *
    // *

    const title = document.querySelectorAll(".js-accordion-title");

    if (title !== null) {
        for (var i=0; i < title.length; i++) {
            title[i].onclick = function(e) {
                this.classList.toggle("open");
                this.nextElementSibling.classList.toggle("open");

                let expanded = this.getAttribute('aria-expanded');
        
                if (expanded == 'false') {
                    this.setAttribute('aria-expanded', 'true')
                } else {
                    this.setAttribute('aria-expanded', 'false')
                }
            }
            title[i].addEventListener("keydown", function (event) {
                if (event.keyCode === 13 || event.keyCode === 32) {
        
                    event.preventDefault();
        
                    this.classList.toggle("open");
                    this.nextElementSibling.classList.toggle("open");
        
                    let expanded = this.getAttribute('aria-expanded');
        
                    if (expanded == 'false') {
                        this.setAttribute('aria-expanded', 'true')
                    } else {
                        this.setAttribute('aria-expanded', 'false')
                    }
                }
            });
        }
    }


    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * open external links in new tab
    // *
    // *

    function externalLinks() {
        for(let links = document.getElementsByTagName("a"), number = 0; number < links.length; number++) {
            let currentLink = links[number];
            let jsTest = currentLink.toString().includes("javascript");
            
            if (jsTest == false){
                // check if link has same hostname. if not add target blank.
                currentLink.getAttribute("href") && currentLink.hostname !== location.hostname && (currentLink.target = "_blank")
            }
        }
    };
    externalLinks();




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * simpleParallax
    // *
    // *

    let parallaxImages = document.getElementsByClassName('js-parallax');
    new simpleParallax(parallaxImages, {
        delay: 1,
        scale: 1.25
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * aos
    // *
    // *

    AOS.init({
        offset: 200,
        duration: 700,
        delay: 100,
        disable: 'mobile',
        easing: 'ease-out-back'
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * menu desktop
    // *
    // *

    document.addEventListener('scroll', function(e) {
        if (window.innerWidth < 992) {
            if (window.scrollY > 400) {
                document.querySelector(".js-header-touch").classList.add("show");
                document.querySelector(".js-header-mobile").classList.remove("show");
            } else {
                document.querySelector(".js-header-touch").classList.remove("show");
                document.querySelector(".js-header-mobile").classList.add("show");
            }
        }
        if (window.innerWidth > 992) {
            if (window.scrollY > 400) {
                document.querySelector(".js-header-scroll").classList.add("show");
                document.querySelector(".js-header-desktop").classList.remove("show");
            } else {
                document.querySelector(".js-header-scroll").classList.remove("show");
                document.querySelector(".js-header-desktop").classList.add("show");
            }
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * menu desktop
    // *
    // *

    const hamburgersDesktop = document.querySelectorAll(".js-menu-hamburger");
    const menu = document.querySelector(".js-header-scroll");

    if (hamburgersDesktop.length > 0) {
        hamburgersDesktop.forEach(hamburger => {
            hamburger.onclick = function(e) {
                hamburger.classList.toggle('open');
                menu.classList.toggle("open");  
            }
        });
    }

    document.addEventListener('keydown', function(event) {
        if (event.key === "Escape") {
            event.preventDefault();
            hamburgersDesktop.forEach(hamburger => {
                hamburger.classList.remove('open');
                menu.classList.remove("open");
            });
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * menu mobile
    // *
    // *

    const hamburgersMobile = document.querySelectorAll(".js-menu-mobile-hamburger");
    const menuMobile = document.querySelector(".js-menu");

    if (hamburgersMobile.length > 0) {
        hamburgersMobile.forEach(hamburger => {
            hamburger.onclick = function(e) {
                hamburger.classList.toggle('open');
                menuMobile.classList.toggle("open");  
            }
        });
    }

    document.addEventListener('keydown', function(event) {
        if (event.key === "Escape") {
            event.preventDefault();
            hamburgersMobile.forEach(hamburger => {
                hamburger.classList.remove('open');
                menuMobile.classList.remove("open");
            });
        }
    });

});


// * * * * * * * * * * * * * * * * * * * * * * * * *
// * hash link jump to content
// *
// *

window.addEventListener('load', function () {
    let getHash = window.location.hash;
    let elementExists = document.getElementById("my" + getHash.slice(1));

    if (getHash !== null && elementExists !== null) {
        let name = "#my" + getHash.slice(1);
        let offsetTop = document.querySelector(name).offsetTop - 60;
        
        if (window.innerWidth > 1200) {
            offsetTop = offsetTop - 80
        }

        scroll({
            top: offsetTop,
            behavior: "smooth"
        });
    }
}, false);